import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import "./layout.css"
import Header from "../components/layout/header"
import Footer from "../components/layout/footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Header />
      <ChildrenWrapper>
        <main>{children}</main>
      </ChildrenWrapper>
      <Footer />
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Wrapper = styled.div`
  margin: 0 auto;
`

const ChildrenWrapper = styled.div`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
`
