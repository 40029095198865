import React from "react"
import styled from "styled-components"
import useLanguage from "../../states/context/websiteContext"
import { Language } from "../../states/context/websiteReducer"

const Footer = () => {
  const { language } = useLanguage()
  const isEnglish = language == Language.en
  return (
    <Wrapper>
      <ContentWrapper>
        <RowWrapper>
          <Logo
            src="/images/logoWhite.svg"
            onClick={() => (location.href = "/")}
          />
          <ContactSection>
            <Title>{isEnglish ? "Contact us for any inquiries" : "Contactez-nous pour toute interrogation"}</Title>
            <a href="mailto:info@leclanjette.com" target="_blank">
              info@leclanjette.com
            </a>
            <br />
            <a href="tel:514-432-7446" target="_blank">
              +1-514-432-7446
            </a>
          </ContactSection>

          <FacebookSection
            className="facebook"
            onClick={() =>
              window.open("https://www.facebook.com/leclanjette/", "_blank")
            }
          >
            <FacebookLogo src="/images/facebook.svg" />
            <p>{isEnglish ? "Follow us on Facebook!" : "Suivez-nous sur Facebook!"}</p>
          </FacebookSection>
        </RowWrapper>
        <Copyright>
          <p>© {new Date().getFullYear()} Le Clan Jetté Forage Inc.</p>
        </Copyright>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  background: #005085;
  margin: 40px 0 0 0;
`

const ContentWrapper = styled.div`
  padding: 80px;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 40px;
  }
`

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: flex-start;
  justify-items: space-between;

  @media (max-width: 940px) {
    grid-template-columns: repeat(2, auto);

    .facebook {
      display: none;
    }
  }

  @media (max-width: 650px) {
    grid-template-columns: auto;
    gap: 20px;
    justify-content: left;
    margin-bottom: 20px;

    .facebook {
      display: grid;
    }
  }
`

const Logo = styled.img`
  cursor: pointer;

  @media (max-width: 380px) {
    width: 200px;
  }
`

const ContactSection = styled.div`
  a {
    text-align: left;
    color: white;
    font-size: 15px;
    cursor: pointer;
  }
`

const Title = styled.p`
  text-align: left !important;
  font-weight: bold;
  font-size: 15px;
  line-height: 50px;

  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;

  @media (max-width: 380px) {
    line-height: 20px;

  }
`

const FacebookSection = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  gap: 10px;
  align-items: center;
  justify-self: end;
  font-size: 15px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
`

const FacebookLogo = styled.img``

const Copyright = styled.div`
  color: white;
  text-transform: uppercase;
  width: 100%;
  text-align: end;

  @media (max-width: 650px) {
    text-align: left;
  }
`
