export const menuData = [
  {
    title: "Home",
    link: "/"
  },
  {
    title: "About us",
    link: "/about"
  },
  {
    title: "Services",
    link: "/services"
  },
  {
    title: "Contact",
    link: "/contact"
  },
]

export const menuDataFr = [
  {
    title: "Accueil",
    link: "/"
  },
  {
    title: "À propos",
    link: "/about"
  },
  {
    title: "Services",
    link: "/services"
  },
  {
    title: "Contact",
    link: "/contact"
  },
]