import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import useWebsite from "../../states/context/websiteContext"
import { Language } from "../../states/context/websiteReducer"
import MenuButton from "../buttons/menuButton"
import { menuData, menuDataFr } from "../../data/menuData"

interface StyledProps {
  isOpen?: boolean
}

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { setEnglish, setFrench, language } = useWebsite()
  const isEnglish = language == Language.en

  return (
    <Wrapper>
      <Logo src="/images/logo.png" onClick={() => (location.href = "/")} />
      <MenuWrapper>
        <LinksWrapper>
          {(isEnglish ? menuData : menuDataFr).map((menu, index) => {
            return (
              <NavbarLink
                key={index}
                onClick={() => {
                  navigate(menu.link)
                }}
              >
                <p>{menu.title}</p>
              </NavbarLink>
            )
          })}
          {language == Language.en ? (
            <LanguageLink
              onClick={() => {
                setFrench()
              }}
            >
              <p>Français</p>
            </LanguageLink>
          ) : (
            <LanguageLink
              onClick={() => {
                setEnglish()
              }}
            >
              <p>English</p>
            </LanguageLink>
          )}
        </LinksWrapper>
        <TelephoneLink href="tel:514-432-7446">
          <TelephoneImage src="/images/telephone.svg" />
          <p>514-432-7446</p>
        </TelephoneLink>
      </MenuWrapper>
      <MenuButton onClick={() => setIsOpen(!isOpen)} />
      <MobileMenuWrapper isOpen={isOpen}>
        {(isEnglish ? menuData : menuDataFr).map((menu, index) => {
          return (
            <NavbarLink
              key={index}
              onClick={() => {
                navigate(menu.link)
              }}
            >
              <p>{menu.title}</p>
            </NavbarLink>
          )
        })}
        {language == Language.en ? (
          <LanguageLink
            onClick={() => {
              setFrench()
            }}
          >
            <p>Français</p>
          </LanguageLink>
        ) : (
          <LanguageLink
            onClick={() => {
              setEnglish()
            }}
          >
            <p>English</p>
          </LanguageLink>
        )}
      </MobileMenuWrapper>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-items: space-between;
  align-items: center;
  padding: 40px;
  max-width: 1440px;
  margin: 0 auto;

  #menuButton {
    display: none;
  }
  @media (max-width: 1120px) {
    #menuButton {
      display: flex;
      justify-self: end;
    }
  }
`

const MenuWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);

  @media (max-width: 1120px) {
    display: none;
  }
`

const MobileMenuWrapper = styled.div<StyledProps>`
  display: none;
  opacity: ${props => (props.isOpen ? "1" : "0")};
  pointer-events: ${props => (props.isOpen ? "visible" : "none")};
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-top: 20px;
  height: ${props => (props.isOpen ? "fit-content" : "0")};

  p {
    width: fit-content;
  }

  @media (max-width: 1120px) {
    display: block;
  }
`

const Logo = styled.img`
  cursor: pointer;

  @media (max-width: 450px) {
    width: 200px;
  }
`

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  max-width: 500px;
  justify-items: center;
`

const NavbarLink = styled.div<StyledProps>`
  cursor: pointer;
  text-transform: uppercase;
  width: fit-content;
  padding-bottom: 10px;

  &:hover {
    font-weight: 900;
  }
`

const LanguageLink = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  width: fit-content;
  padding-bottom: 10px;
  border-bottom: 10px solid transparent;

  &:hover {
    font-weight: 900;
  }
`

const TelephoneLink = styled.a`
  display: grid;
  grid-template-columns: 30px auto;
  justify-self: end;
  gap: 10px;
  color: black;
  width: 200px;
  letter-spacing: 0.1em;
  padding-bottom: 20px;
`

const TelephoneImage = styled.img`
  width: 30px;
  height: 30px;
`
